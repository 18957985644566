import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function checkSpecificationSheet(mediaInfo) {
  return mediaInfo?.specificationSheet?.filter((sheet) => sheet?.fileName || sheet?.url);
}

function checkMSDSheet(mediaInfo) {
  return mediaInfo?.msdsSheet?.filter((sheet) => sheet?.fileName || sheet?.url);
}

function CommonMediaWidgets({ mediaInfo }) {
  const { t } = useTranslation();
  const history = useHistory();

  let loadItem = (supc) => {
    history.push('/supc/' + supc);
  };

  return (
    <>
      {mediaInfo?.specificationSheet?.length ? (
        <div id="item-specification-sheet" className="specification-sheets">
          <div className="info-row">
            <div id="item-specification-sheet-block" className="data-block ">
              {checkSpecificationSheet(mediaInfo) ? (
                <div id="item-specification-sheet-label" c className="label">
                  {t('mediaWidgetColumn.specificationSheet')}
                </div>
              ) : null}
              <ul id="item-specification-sheet-list">
                {mediaInfo?.specificationSheet.map((sheet, i) => (
                  <li id={`item-specification-sheet-${i + 1}-file-thumbnail`} className="file-thumbnail">
                    <a id={`item-specification-sheet-${i + 1}-url`} className="icon" href={sheet?.url} target="_BLANK" download></a>
                    <div className="info">
                      <div className="filename">
                        <a id={`item-specification-sheet-${i + 1}-name`} href={sheet?.url} target="_BLANK">
                          {sheet?.fileName}
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ): null}
      {mediaInfo?.msdsSheet?.length ? (
        <div id="item-msds-sheet" className="msds-sheets">
          <div className="info-row">
            <div id="item-msds-sheet-block" className="data-block ">
              {checkMSDSheet(mediaInfo) ? (
                <div id="item-msds-sheet-label" className="label">
                  {t('mediaWidgetColumn.msdsSheet')}
                </div>
              ) : null}
              <ul id="item-msds-sheet-list">
                {mediaInfo?.msdsSheet.map((sheet, i) => (
                  <li id={`item-msds-sheet-${i + 1}-file-thumbnail`} className="file-thumbnail">
                    <a id={`item-msds-sheet-${i + 1}-url`} className="icon" href={sheet?.url} target="_BLANK" download></a>
                    <div className="info">
                      <div className="filename">
                        <a id={`item-msds-sheet-${i + 1}-name`} href={sheet?.url} target="_BLANK">
                          {sheet?.fileName}
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ): null}
      {mediaInfo?.recipes?.length ? (
        <div id="item-recipes" className="recipes">
          <div className="info-row">
            <div id="item-recipes-block" className="data-block ">
              <div id="item-recipes-label" className="label">
                {t('mediaWidgetColumn.recipes')}
              </div>
              <ul id="item-recipes-list">
                {mediaInfo?.recipes.map((recipe, i) => (
                  <li id={`item-recipe-${i + 1}-file-thumbnail`} className="file-thumbnail">
                    <a id={`item-recipe-${i + 1}-url`} className="icon" href={recipe?.url} target="_BLANK" download></a>
                    <div className="info">
                      <div className="filename">
                        <a id={`item-recipe-${i + 1}-name`} href={recipe?.url} target="_BLANK">
                          {recipe?.recipeName}
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}
      {mediaInfo?.relatedItems?.length ? (
        <div id="item-related-items" className="related-items">
          <div className="info-row">
            <div id="item-related-items-block" className="data-block">
              <div id="item-related-items-label" className="label">
                {t('mediaWidgetColumn.relatedItems')}
              </div>
              <ul id="item-related-items-list">
                {mediaInfo?.relatedItems.map((item, i) => (
                  <li id={`item-related-item-${i + 1}`} className="thumbnail-block item-thumbnail">
                    <a id={`item-related-item-${i + 1}-btn`} onClick={() => loadItem(item?.supc)}>
                      <div
                        id={`item-related-item-${i + 1}-img`}
                        className="image"
                        style={{ background: 'url(' + item?.imageUrl + ') no-repeat center center/cover' }}
                      ></div>
                    </a>
                    <div id={`item-related-item-${i + 1}-info`} className="info">
                      <a
                        id={`item-related-item-${i + 1}-info-btn`}
                        className="related-items"
                        onClick={() => loadItem(item?.supc)}
                      >
                        <div id={`item-related-item-${i + 1}-supc`} className="value-field">
                          <span id={`item-related-item-${i + 1}-label`}>{t('mediaWidgetColumn.supc')}</span>{' '}
                          {item?.supc}
                        </div>
                        <div id={`item-related-item-${i + 1}-title`} className="title">
                          {item?.description}
                        </div>
                        <div id={`item-related-item-${i + 1}-subtitle`} className="subtitle">
                          {item?.brand}
                        </div>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CommonMediaWidgets;
