import { combineReducers } from 'redux';
import initialState from '../store/initialState';
import { ITEM_DATA } from '../actions/actionTypes';
import { createReducer } from '../reduxHelpers';

const itemData = createReducer(ITEM_DATA, initialState.itemData);

export default combineReducers({
  itemData,
});
