import React, { useState } from 'react';
import CommonMediaWidgets from './CommonMediaWidgets';
import ReactImageMagnify from 'react-image-magnify';


function checkMediaMainImage(media){
  if(media?.mainImage){
    return media?.mainImage;
  }
  return (media?.mainImage === '' || media?.mainImage === null) && media?.videos?.length > 0
  ? media?.videos[0]?.thumbnail
  : null;
}

function checkImageLength(images) {
  return images?.length > 0 && images;
}

function checkVideoLength(media) {
  return media?.videos?.length > 0 && (media?.mainImage === '' || media?.mainImage === null);
}

function MediaColumn({ media, mediaInfo }) {
  const [mainImage, setMainImage] = useState(checkMediaMainImage(media));
  const [videoSrc, setvideoSrc] = useState(checkVideoLength(media) ? media?.videos[0]?.url : '');
  const [isVideoImage, setisVideoImage] = useState(checkVideoLength(media) ? true : false);
  const [showPreview, setShowPreview] = useState(false);
  const [isVideoPreview, setisVideoPreview] = useState(false);

  const ChangeMainImage = (targetelem, isVideo, videoUrl) => {
    setMainImage(targetelem);
    isVideo ? setVideoParams(videoUrl) : setisVideoImage(false);
  };

  const setVideoParams = (videoUrl) => {
    setvideoSrc(videoUrl);
    setisVideoImage(true);
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
    isVideoImage ? setisVideoPreview(true) : setisVideoPreview(false);
  };

  const images = [];

  media?.lifestyleImageUrl &&
    images.push(
      <li
        id="item-gallery-lifestyle-img"
        style={{ background: 'url(' + media?.lifestyleImageUrl + ') no-repeat center center/contain' }}
        onClick={() => ChangeMainImage(media?.lifestyleImageUrl)}
      ></li>
    );

  images.push(
    media?.gs1Images?.map((image, i) => (
      <li
        id={`item-gallery-gs1-img-${i + 1}`}
        key={`gs1-images-tile-${i + 1}`}
        style={{ background: 'url(' + image + ') no-repeat center center/contain' }}
        onClick={() => ChangeMainImage(image)}
      ></li>
    ))
  );

  images.push(
    media?.otherImageUrls?.map((url, i) => (
      <li
        id={`item-gallery-other-img-${i + 1}`}
        key={`other-images-tile-${i + 1}`}
        style={{ background: 'url(' + url + ') no-repeat center center/contain' }}
        onClick={() => ChangeMainImage(url)}
      ></li>
    ))
  );

  return (
    <div id="item-media" className="media-column">
      <div id="item-images" className="product-images">
        {mainImage && !isVideoImage && (
          <div id="item-main-image-preview" className="main adjusted-for-source" onClick={togglePreview}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: 'Magnifier',
                  isFluidWidth: true,
                  src: mainImage,
                },
                largeImage: {
                  src: mainImage,
                  width: 1600,
                  height: 1600,
                },
                imageClassName: 'main-img',
                shouldUsePositiveSpaceLens: true,
                enlargedImageContainerDimensions: { width: '180%', height: '180%' },
                imageStyle: { maxHeight: '24vw', minHeight: '24vw', maxWidth: '25vw', minWidth: '25vw' },
                enlargedImageContainerStyle: {
                  borderColor: '#000000',
                  boxShadow: '0 3px 7px rgba(0, 0, 0, 0.5)',
                  zIndex: 10,
                },
              }}
            />
          </div>
        )}
        {mainImage && isVideoImage && (
          <div id="item-main-video-image-preview" className="main adjusted-for-source" onClick={togglePreview}>
            <div id="item-main-video-thumbnail" className="video-thumbnail main-img is-video">
              {mainImage && (
                <img id="item-main-video-thumbnail-img" key="video-image" src={mainImage} onClick={togglePreview} />
              )}
            </div>
          </div>
        )}
        <ul id="item-gallery-section">
          {checkImageLength(images)}
          {media?.videos?.length > 0 &&
            media?.videos?.map((video, i) => (
              <div
                id={`item-gallery-video-thumbnail-tile-${i + 1}`}
                key={`video-thumbnail-tile-${i + 1}`}
                className="video-thumbnail-tile"
                onClick={() => ChangeMainImage(video?.thumbnail, true, video?.url)}
              >
                <li
                  id={`item-gallery-video-${i + 1}`}
                  key={`video-thumbnail-tile-img-${i + 1}`}
                  className="video-thumbnail-tile-img"
                  style={{ background: 'url(' + video?.thumbnail + ') no-repeat center center/cover' }}
                  videoSrc={videoSrc}
                />
              </div>
            ))}
        </ul>
        {showPreview && (
          <div id="item-gallery-preview" className="preview" onClick={togglePreview}>
            <div id="item-gallery-preview-block" className="modal">
              {isVideoPreview ? (
                <video
                  id="item-gallery-main-video"
                  className="adjusted-for-source"
                  width="320"
                  height="240"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  controlsList="nodownload"
                  controls
                >
                  <source id="item-gallery-main-video-src" src={videoSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img id="item-gallery-main-image" className="adjusted-for-source" src={mainImage} />
              )}
            </div>
          </div>
        )}
      </div>
      <CommonMediaWidgets mediaInfo={mediaInfo} />
    </div>
  );
}

export default MediaColumn;
