import React from 'react';
import { useTranslation } from 'react-i18next';

export function MPCAttribute({ label, value, cssClass, id }) {
  const { t } = useTranslation();
  return value ? (
    <div id={`info-column-${id}-datablock`} className={cssClass}>
      <div id={`info-column-${id}-datablock-label`} className="label">
        {t(label)}
      </div>
      <div id={`info-column-${id}-datablock-data`} className="data">
        {value}
      </div>
    </div>
  ) : null;
}

export default MPCAttribute;
