import React from 'react';
import CommonMediaWidgets from './CommonMediaWidgets';

function MediaColumnMobile({ mediaInfo }) {
  return (
    <div id="mobile-media-col" className="media-column mobile-mode mobile-sheets">
      <CommonMediaWidgets mediaInfo={mediaInfo} />
    </div>
  );
}

export default MediaColumnMobile;
