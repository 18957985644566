import React from 'react';

function Loader({ show }) {
  return (
    <div className={show ? 'app-loader show' : 'app-loader'}>
      <div class="ring-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
