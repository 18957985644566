import React from 'react';
import { useTranslation } from 'react-i18next';

function NutritionalColumn({ nutritionalInfo }) {
  const { t } = useTranslation();

  const attributeImages = [];

  nutritionalInfo?.attributeImages?.length > 0 &&
    attributeImages.push(
      nutritionalInfo?.attributeImages?.map((attributeImage, i) => (
        <li id={`item-attribute-img-${i + 1}`}>
          <img id={`item-attribute-img-${i + 1}-src`} src={attributeImage} />
        </li>
      ))
    );

  const certifications = [];

  nutritionalInfo?.certifications?.length > 0 &&
    certifications.push(
      nutritionalInfo?.certifications?.map((certification, i) => (
        <li id={`item-certification-${i + 1}`} className="file-thumbnail">
          <a
            id={`item-certification-${i + 1}-url`}
            className="icon"
            href={certification?.url}
            target="_BLANK"
            download
          ></a>
          <div className="info">
            <div id={`item-certification-${i + 1}-file-name`} className="filename">
              <a id={`item-certification-${i + 1}-file-name-value`} href={certification?.url} target="_BLANK">
                {certification?.fileName}
              </a>
            </div>
            {/* <div className="update-date">{certification?.updateDate}</div> */}
          </div>
        </li>
      ))
    );

  return nutritionalInfo ? (
    <div id="item-nutritional-info" className="nutritional-column">
      {nutritionalInfo?.nutritions && (
        <div id="item-nutritional-panel" className="nutrition-block adjusted-for-source">
          <header id="item-nutritional-header">
            Nutrition Facts <div className="french">Valeur nutritive</div>
          </header>

          <div id="item-serving-size" className="serving-size">
            Serving Size ({nutritionalInfo.nutritions?.servingSize}
            {nutritionalInfo.nutritions?.servingSizeUOM})
            <div id="item-serving-size-fr" className="french">
              Portion ({nutritionalInfo.nutritions?.servingSize}
              {nutritionalInfo.nutritions?.servingSizeUOM})
            </div>
          </div>

          <div id="item-calories" className="nutrition-row calories no-border">
            <div id="item-calories-label" className="label">
              Calories{' '}
              <span id="item-calories-value" className="data">
                {nutritionalInfo.nutritions?.calories}
              </span>
            </div>

            <div id="item-daily-value" className="dv">
              % Daily Value*{' '}
              <div id="item-daily-value-fr" className="french">
                % Valeur quotidienne*
              </div>
            </div>
          </div>

          <div id="item-fat-info" className="nutrition-row section-level-1 no-border">
            <div id="item-fat-info-label" className="label">
              Fat / Lipides
              <span id="item-fat-info-value" className="data">
                {nutritionalInfo.nutritions?.totalFat}
                {nutritionalInfo.nutritions?.totalFatUOM}
              </span>
            </div>

            <div id="item-fat-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.totalFatDV}%
            </div>
          </div>

          <div id="item-saturated-block" className="nutrition-row sub-section no-border">
            <div id="item-saturated-label" className="label">
              Saturated / Saturés
              <span id="item-saturated-value" className="data">
                {nutritionalInfo.nutritions?.saturated}
                {nutritionalInfo.nutritions?.saturatedUOM}
              </span>
            </div>

            <div id="item-saturated-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.saturatedDV}%
            </div>
          </div>

          <div id="item-trans-fat-block" className="nutrition-row sub-section">
            <div id="item-trans-fat-label" className="label">
              + Trans / trans
              <span id="item-trans-fat-value" className="data">
                {nutritionalInfo.nutritions?.transFat}
                {nutritionalInfo.nutritions?.transFatUOM}
              </span>
            </div>
          </div>
          <div id="item-carbohydrates-block" className="nutrition-row section-level-1 no-border">
            <div id="item-carbohydrates-label" className="label">
              Carbohydrate / Glucides
              <span id="item-carbohydrates-value" className="data">
                {nutritionalInfo.nutritions?.carbohydrates}
                {nutritionalInfo.nutritions?.carbohydratesUOM}
              </span>
            </div>
          </div>
          <div id="item-fibre-block" className="nutrition-row sub-section no-border">
            <div id="item-fibre-label" className="label">
              Fibre / Fibres
              <span id="item-fibre-value" className="data">
                {nutritionalInfo.nutritions?.fibre}
                {nutritionalInfo.nutritions?.fibreUOM}
              </span>
            </div>

            <div id="item-fibre-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.fibreDV}%
            </div>
          </div>
          <div id="item-sugars-block" className="nutrition-row sub-section">
            <div id="item-sugars-label" className="label">
              Sugars / Sucres
              <span id="item-sugars-value" className="data">
                {nutritionalInfo.nutritions?.sugars}
                {nutritionalInfo.nutritions?.sugarsUOM}
              </span>
            </div>

            <div id="item-sugars-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.sugarsDV}%
            </div>
          </div>
          <div id="item-proteins-block" className="nutrition-row section-level-1">
            <div id="item-proteins-label" className="label">
              Protein / Protéines
              <span id="item-proteins-value" className="data">
                {nutritionalInfo.nutritions?.proteins}
                {nutritionalInfo.nutritions?.proteinsUOM}
              </span>
            </div>
          </div>
          <div id="item-cholesterol-block" className="nutrition-row section-level-1">
            <div id="item-cholesterol-label" className="label">
              Cholesterol
              <span id="item-cholesterol-value" className="data">
                {nutritionalInfo.nutritions?.cholesterol}
                {nutritionalInfo.nutritions?.cholesterolUOM}
              </span>
            </div>
          </div>
          <div id="item-sodium-block" className="nutrition-row section-level-1 divider-bottom">
            <div id="item-sodium-label" className="label">
              Sodium
              <span id="item-sodium-value" className="data">
                {nutritionalInfo.nutritions?.sodium}
                {nutritionalInfo.nutritions?.sodiumUOM}
              </span>
            </div>

            <div id="item-sodium-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.sodiumDV}%
            </div>
          </div>
          <div id="item-potassium-block" className="nutrition-row">
            <div id="item-potassium-label" className="label">
              Potassium
              <span id="item-potassium-value" className="data">
                {nutritionalInfo.nutritions?.potassium}
                {nutritionalInfo.nutritions?.potassiumUOM}
              </span>
            </div>
            <div id="item-potassium-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.potassiumDV}%
            </div>
          </div>
          <div id="item-calcium-block" className="nutrition-row">
            <div id="item-calcium-label" className="label">
              Calcium
              <span id="item-calcium-value" className="data">
                {nutritionalInfo.nutritions?.calcium}
                {nutritionalInfo.nutritions?.calciumUOM}
              </span>
            </div>

            <div id="item-calcium-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.calciumDV}%
            </div>
          </div>
          <div id="item-iron-block" className="nutrition-row divider-bottom">
            <div id="item-iron-label" className="label">
              Iron / Fer
              <span id="item-iron-value" className="data">
                {nutritionalInfo.nutritions?.iron}
                {nutritionalInfo.nutritions?.ironUOM}
              </span>
            </div>

            <div id="item-iron-daily-value" className="dv-data ">
              {nutritionalInfo.nutritions?.ironDV}%
            </div>
          </div>
          <div id="item-daily-value-note" className="nutrition-row dv-note">
            *5% or less is <span> a little</span>, 15% or more is <span> a lot</span>
            <div id="item-daily-value-note-fr" className="french">
              *5% ou moins c'est un <span>peu</span>, 15% ou plus c'est <span>beaucoup</span>
            </div>
          </div>
        </div>
      )}
      {nutritionalInfo?.ingredients && (
        <div id="item-ingredients" className="ingredients">
          <div className="info-row">
            <div id="item-ingredients-block" className="data-block ">
              <div id="item-ingredients-label" className="label">
                {t('nutritionalColumn.ingredients')}
              </div>
              <div id="item-ingredients-value" className="data description">
                {nutritionalInfo.ingredients}
              </div>
            </div>
          </div>
        </div>
      )}
      {(attributeImages?.length > 0 || certifications?.length > 0) && (
        <div id="item-certifications" className="certifications">
          <div className="info-row">
            <div id="item-certifications-block" className="data-block ">
              <div id="item-certifications-label" className="label">
                {t('nutritionalColumn.certifications')}
              </div>
              {attributeImages?.length > 0 && (
                <ul id="item-attribute-images-list" className="logos">
                  {attributeImages}
                </ul>
              )}
              {certifications?.length && <ul id="item-certifications-list">{certifications}</ul>}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
}

export default NutritionalColumn;
