import React from 'react';
import { useTranslation } from 'react-i18next';
import AllergenStaticAttribute from './AllergenStaticAttribute';

function Allergens({ coreData }) {
  const { t } = useTranslation();
  return coreData?.allergens?.contains || coreData?.allergens?.mayContain ? (
    <div id="item-allergens-info" className="info-row column-labels">
      <label id="item-allergens-label" className="label remove-border-padding">
        {t('infoColumn.allergens')}
      </label>
      <div className="data-block-group">
        <AllergenStaticAttribute
          label={'infoColumn.allergens.contains'}
          value={coreData?.allergens?.contains}
          id={'contains'}
        />

        <AllergenStaticAttribute
          label={'infoColumn.allergens.mayContain'}
          value={coreData?.allergens?.mayContain}
          id={'may-contain'}
        />
      </div>
    </div>
  ) : null;
}

export default Allergens;
