import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ReadMoreReadLess({ fullText, charLimit }) {
  const { t } = useTranslation();

  let [overviewTextClipped, setoverviewTextClipped] = useState(fullText?.length > charLimit);

  const toggleoverviewTextClipped = () => {
    setoverviewTextClipped(!overviewTextClipped);
  };

  return (
    <div id="item-overview" className="overview">
      {overviewTextClipped ? (
        <div id="item-overview-clipped" className="clipped-text">
          {fullText?.substring(0, charLimit)}...{' '}
          <a id="item-overview-expand" onClick={toggleoverviewTextClipped}>
            {t('infoColumn.readMore')}
          </a>
        </div>
      ) : (
        <div id="item-overview-whole" className="full-text">
          {fullText}
          {fullText?.length > charLimit && (
            <a id="item-overview-shrink" onClick={toggleoverviewTextClipped}>
              {t('infoColumn.seeLess')}
            </a>
          )}
        </div>
      )}
    </div>
  );
}
