import React from 'react';

const ErrorPage = ({ error }) => {
  return (
    <div className="wrapper error-page">
      <div class="error-panel">
        <h1>
          <i className="icon fi flaticon-round-delete-button" />
          {error.name}
        </h1>
        <h2>{error.message}</h2>
      </div>
    </div>
  );
};

export default ErrorPage;
