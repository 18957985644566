import ReactGa from 'react-ga';

/**
 * Initialize google analytics for the application
 */
const initGoogleAnalytics = () => {
  ReactGa.initialize(process.env.REACT_APP_GA_CODE);
  ReactGa.pageview(window.location.pathname + window.location.search);
};

export default initGoogleAnalytics;
