import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getRequest } from '../_http';
import { ITEM_DATA } from '../actions/actionTypes';
import i18n from '../_i18n';

function* loadCoreDataAsync({ gtin, supc }) {
  try {
    const response = yield call(getRequest, '/item/details', { gtin: gtin, supc: supc, locale: i18n.language });
    yield put({ type: ITEM_DATA.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ITEM_DATA.FAILURE, payload: error });
  }
}

function* watchLoadcoreData() {
  yield takeLatest(ITEM_DATA.REQUEST, loadCoreDataAsync);
}

export default function* rootSaga() {
  yield all([watchLoadcoreData()]);
}
