import React, { useEffect, useRef } from 'react';
import MediaColumn from './components/MediaColumn/MediaColumn';
import MediaColumnMobile from './components/MediaColumn/MediaColumnMobile';
import InfoColumn from './components/InfoColumn/infoColumn';
import NutritionalColumn from './components/NutritionalColumn/NutritionalColumn';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as allActions from './actions';
import ErrorPage from './components/ErrorPage';
import Loader from './components/Loader';
import { useTranslation } from 'react-i18next';
import i18n from './_i18n';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

function selectedLanguage(selectedLang) {
  return i18n.language === selectedLang ? 'lang-selected' : '';
}

function ProductSheet({
  actions,
  gtin,
  supc,
  error,
  fetching,
  mediaInfo,
  media,
  nutritionalInfo,
  disclaimer,
  coreData,
  flags,
  lang,
}) {
  useEffect(() => {
    loadLanguage();
    actions.loadItemData(gtin, supc);
  }, [actions, gtin, supc, i18n.language, lang]);

  const { t } = useTranslation();
  const history = useHistory();

  const componentRef = useRef();
  const download = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'blank',
  });

  function setLanguage(setLang) {
    if (supc) {
      history.push(`/supc/${supc}?lang=${setLang === 'fr' ? 'CANFRE' : 'USAENG'}`);
    } else {
      history.push(`/gtin/${gtin}?lang=${setLang === 'fr' ? 'CANFRE' : 'USAENG'}`);
    }
  }

  function loadLanguage() {
    const lan = lang === 'CANFRE' ? 'fr' : 'en';
    i18n.changeLanguage(lan);
  }

  if (fetching) {
   return <Loader show/>
  }
  return error ? (
    <ErrorPage error={error} />
  ) : (
    <>
    <header id="mini-header" className="mini-header-panel">
      <div id="report-issue" className="report-link">
        <a id="report-issue-link" href={'mailto:Canada-ItemEnrichment@corp.sysco.ca'} target="_BLANK">
          <div id="report-issue-icon" className="report-icon"></div> {t('productSheet.reportIssue')}
        </a>
      </div>
      <div id="product-sheet-print-pdf" className="product-sheet-print-wrap">
        <span onClick={download} id="product-sheet-print-pdf-icon" className="product-sheet-print"></span>
      </div>
      <span id="language-selector" className="language">
        <a id="language-selector-en" className={selectedLanguage('en')} onClick={() => setLanguage('en')}>
          EN
        </a>
        <span> I </span>
        <a id="language-selector-fr" className={selectedLanguage('fr')} onClick={() => setLanguage('fr')}>
          FR
        </a>
      </span>
    </header>
    <div id="product-sheet-container" className="wrapper product-sheet-wrapper" ref={componentRef}>
      <MediaColumn media={media} mediaInfo={mediaInfo} />
      <InfoColumn coreData={coreData} disclaimer={disclaimer} flags={flags} />
      <NutritionalColumn nutritionalInfo={nutritionalInfo} />
      <MediaColumnMobile mediaInfo={mediaInfo} />
    </div>
  </>
  );
}

function mapStateToProps(state) {
  return {
    coreData: state.itemData.data?.coreData,
    mediaInfo: state.itemData.data?.mediaInfo,
    media: state.itemData.data?.media,
    nutritionalInfo: state.itemData.data?.nutritionalInfo,
    disclaimer: state.itemData.data?.disclaimer,
    flags: state.itemData.data?.flags,
    error: state.itemData.error,
    fetching: state.itemData.fetching,
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(allActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSheet);
