import React from 'react';
import { useTranslation } from 'react-i18next';
import ReadMoreReadLess from '../ReadMoreReadLess/ReadMoreReadLess';
import InfoColumnStaticAttribute from './InfoColumnStaticAttribute';
import Allergens from './Allergens';
import FABStaticAttributes from './FABStaticAttributes';
import MPCAttribute from './MPCAttribute';

function InfoColumn({ coreData, disclaimer, flags }) {
  const { t } = useTranslation();
  const attributes = [];
  attributes.push(
    coreData?.attributes?.productHighlights?.map((highlight, i) => (
      <li
        id={`item-attribute-highlight-${i + 1}`}
        className="tag highlight"
        key={`item-attribute-highlight-${i + 1}`}
        title="Product highilight"
      >
        <div id={`item-attribute-highlight-icon-${i + 1}`} className="canada-icon"></div>
        {highlight}
      </li>
    ))
  );

  attributes.push(
    coreData?.attributes?.allergens?.map((allergen, i) => (
      <li
        id={`item-attribute-allergen-${i + 1}`}
        key={`item-attribute-allergen-${i + 1}`}
        className="tag allergens"
        title="Allergen"
      >
        {allergen}
      </li>
    ))
  );

  attributes.push(
    coreData?.attributes?.productClaims?.map((claim, i) => (
      <li
        id={`item-attribute-claim-${i + 1}`}
        key={`item-attribute-claim-${i + 1}`}
        className="tag claims"
        title="Product Claim"
      >
        {claim}
      </li>
    ))
  );

  attributes.push(
    coreData?.attributes?.dietTypes?.map((dietType, i) => (
      <li
        id={`item-attribute-diet-type-${i + 1}`}
        key={`item-attribute-diet-type-${i + 1}`}
        className="tag diet"
        title="Diet type"
      >
        {dietType}
      </li>
    ))
  );

  attributes.push(
    coreData?.attributes?.local?.map((attribute, i) => (
      <li
        id={`item-attribute-local-${i + 1}`}
        key={`item-attribute-local-${i + 1}`}
        className="tag local"
        title={attribute}
      >
        <div id={`item-attribute-local-icon-${i + 1}`} className="local-icon" />
        {attribute}
      </li>
    ))
  );

  console.log("coreData?.packagingInformation", coreData?.packagingInformation)

  console.log("coreData?.marketingStatements", coreData?.marketingStatements)

  return (
    <div id="item-information" className="info-column">
      <div id="item-title" className="title-description">
        {coreData?.titleDescription}
      </div>

      <ReadMoreReadLess fullText={coreData?.productDescriptor} charLimit="250" />

      <div id="item-manufacturer-information" className="info-row brand-manufacturer-wrapper">
        <InfoColumnStaticAttribute
          label={'infoColumn.brand'}
          value={coreData?.brand}
          cssClass={'data-block brand'}
          id={'brand'}
        />
        <InfoColumnStaticAttribute
          label={'infoColumn.mpc'}
          value={coreData?.manufacturer}
          cssClass={'data-block manufacturer'}
          id={'manufacturer'}
        />
      </div>

      <div className="info-row inline-labels">
        <InfoColumnStaticAttribute
          label={'infoColumn.supc'}
          value={coreData?.supc}
          cssClass={'data-block supc'}
          id={'supc'}
        />
        <InfoColumnStaticAttribute
          label={'infoColumn.gtin'}
          value={coreData?.gtin}
          cssClass={'data-block gtin'}
          id={'gtin'}
        />
      </div>

      <div className="info-row inline-labels">
        <InfoColumnStaticAttribute
          label={'infoColumn.class'}
          value={coreData?.itemClass}
          cssClass={'data-block class'}
          id={'class'}
        />
        <InfoColumnStaticAttribute
          label={'infoColumn.category'}
          value={coreData?.category}
          cssClass={'data-block category'}
          id={'category'}
        />
      </div>

      <div className="info-row inline-labels">
        <div id="info-column-class-row" class="info-row inline-labels">
          <MPCAttribute
            label={'infoColumn.gln'}
            value={coreData?.gln}
            cssClass={'data-block category'}
            id={'category'}
          />
          <MPCAttribute
            label={'infoColumn.trueVendor'}
            value={coreData?.trueVendor}
            cssClass={'data-block category'}
            id={'category'}
          />
        </div>
      </div>

      <div className="info-row">
        {/* <ItemStatus coreData={coreData} /> */}

        <InfoColumnStaticAttribute
          label={'infoColumn.pack'}
          value={coreData?.pack}
          cssClass={'data-block pack'}
          id={'pack-count'}
        />
        <InfoColumnStaticAttribute
          label={'infoColumn.itemSize'}
          value={coreData?.itemSize}
          cssClass={'data-block size'}
          id={'size'}
        />
      </div>

      {coreData?.packaging && (
        <div id="item-packaging-info" className="info-row">
          <label id="item-packaging-info-label" className="label">
            {t('infoColumn.packaging')}
          </label>
          {coreData?.packaging?.itemWeight && (
            <div id="item-packaging-weight-block" className="data-block weight">
              <div id="item-packaging-weight-label" className="label">
                {t('infoColumn.packaging.itemWeight')}
              </div>
              <div id="item-packaging-weight-value" className="data">{`${coreData?.packaging?.itemWeight}${
                flags?.catchWeight === 'Y' ? '(Est.)' : ''
              }`}</div>
            </div>
          )}

          <InfoColumnStaticAttribute
            label={'infoColumn.packaging.caseCube'}
            value={coreData.packaging?.caseCube}
            cssClass={'data-block cube'}
            id={'packaging-cube'}
          />

          <InfoColumnStaticAttribute
            label={'infoColumn.packaging.servings'}
            value={coreData.packaging?.servings}
            cssClass={'data-block case'}
            id={'packaging-case'}
          />

          <InfoColumnStaticAttribute
            label={'infoColumn.packaging.servingsUOM'}
            value={coreData.packaging?.servingsUOM}
            cssClass={'data-block uom'}
            id={'packaging-uom'}
          />
        </div>
      )}

      {attributes.some((e) => Array.isArray(e) && e.length > 0) ? (
        <div id="item-attributes-info" className="info-row">
          <div id="item-attributes-block" className="data-block">
            <div id="item-attributes-label" className="label">
              {t('infoColumn.attributes')}
            </div>
            <ul id="item-attributes-tags" className="tags">
              {attributes}
            </ul>
          </div>
        </div>
      ) : null}

      <Allergens coreData={coreData} />
      <span class="info-column-attributes-divider"></span>
      <div id="item-features-and-benefits-main" className="info-row">
        {coreData?.packagingInformation ||
        coreData?.sizeAndShapeOfProduct ||
        coreData?.yieldOrServingSize ||
        coreData?.qualityAndFormat ||
        coreData?.preparationAndCookingInstructions ||
        coreData?.storageAndUsage ||
        coreData?.handlingInstructions ||
        coreData?.additionalProductInformation ||
        coreData?.marketingStatements ||
        coreData?.culinaryApplications ? (
          <div id="item-features-and-benefits-block" className="data-block ">
            <div id="item-features-and-benefits-label" className="label">
              {t('infoColumn.featuresAndBenefits')}
            </div>
          </div>
        ) : null}

        <FABStaticAttributes
          label={'infoColumn.packagingInformation'}
          value={coreData?.packagingInformation}
          id={'packaging-information'}
        />

        <FABStaticAttributes
          label={'infoColumn.sizeAndShapeOfProduct'}
          value={coreData?.sizeAndShapeOfProduct}
          id={'size-and-shape'}
        />

        <FABStaticAttributes
          label={'infoColumn.yieldOrServingSize'}
          value={coreData?.yieldOrServingSize}
          id={'yield-or-serving-size'}
        />

        <FABStaticAttributes
          label={'infoColumn.qualityAndFormat'}
          value={coreData?.qualityAndFormat}
          id={'quality-and-format'}
        />

        <FABStaticAttributes
          label={'infoColumn.preparationAndCookingInstructions'}
          value={coreData?.preparationAndCookingInstructions}
          id={'preparation-and-cooking'}
        />

        <FABStaticAttributes
          label={'infoColumn.storageAndUsage'}
          value={coreData?.storageAndUsage}
          id={'storage-and-usage'}
        />

        <FABStaticAttributes
          label={'infoColumn.handlingInstructions'}
          value={coreData?.handlingInstructions}
          id={'handling-instructions'}
        />

        <FABStaticAttributes
          label={'infoColumn.additionalProductInformation'}
          value={coreData?.additionalProductInformation}
          id={'additional-information'}
        />

        <FABStaticAttributes
          label={'infoColumn.marketingStatements'}
          value={coreData?.marketingStatements}
          id={'marketing-statements'}
        />

        <FABStaticAttributes
          label={'infoColumn.culinaryApplications'}
          value={coreData?.culinaryApplications}
          id={'serving-suggestions'}
        />
      </div>

      <div id="item-about-brand" className="info-row">
        {coreData?.aboutBrand ? (
          <div id="item-about-brand-block" className="data-block ">
            <div id="item-about-brand-label" className="label">
              <figure className="figure-text">{t('infoColumn.aboutTheBrand')}</figure>
            </div>
            <div id="item-about-brand-description" className="data description">
              <figure className="figure-text">
                {coreData.brandLogoURL && (
                  <img
                    id="item-about-brand-img"
                    className="inline-image item-about-brand-img"
                    src={coreData.brandLogoURL}
                  />
                )}
                {coreData?.aboutBrand}
              </figure>
            </div>
          </div>
        ) : null}
      </div>
      <div id="item-disclaimer" className="info-row">
        {disclaimer ? (
          <div id="item-disclaimer-block" className="data-block ">
            <div id="item-disclaimer-label" className="label">
              <figure className="figure-text">{t('infoColumn.disclaimer')}</figure>
            </div>
            <div id="item-disclaimer-value" className="data description">
              <figure className="figure-text"> {disclaimer.content}</figure>
            </div>
            <div id="item-last-modified-date" className="data description">
              <span id="item-last-modified-date-label" style={{ color: '#575b5d' }}>
                {t('infoColumn.lastModifiedDate')}:
              </span>{' '}
              <figure className="figure-text"> {disclaimer.lastModifiedDate}</figure>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default InfoColumn;
