import React from 'react';
import './styles/App.scss';
import ProductSheet from './ProductSheet';
import store from './store';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';

function MainApp(props) {
  const search = useLocation().search;
  const {
    match: { params },
  } = props;
  const lang = new URLSearchParams(search)?.get('lang');

  return (
    <Provider store={store}>
      <ProductSheet gtin={params.gtin} supc={params.supc} lang={lang} />
    </Provider>
  );
}

export default MainApp;
