import React from 'react';
import { useTranslation } from 'react-i18next';

export function FABStaticAttributes({ label, value, id }) {
  const { t } = useTranslation();

  return value ? (
    <div div id={id} className="fandb-items-wrap ">
      <span>
        <div className="label">{t(label)}</div>
        <ul>
          {value?.map((feature, i) => (
            <li id={`${id}-value-${i + 1}`} className="data description">
              {feature}
            </li>
          ))}
        </ul>
      </span>
    </div>
  ) : null;
}

export default FABStaticAttributes;
