import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import MainApp from './MainApp';
import './_i18n';
import initGoogleAnalytics from './utils/google-analytics';
import './styles/App.scss';

function App() {
  useEffect(() => {
    initGoogleAnalytics();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/gtin/:gtin" component={MainApp}></Route>
        <Route exact path="/supc/:supc" component={MainApp}></Route>
      </Switch>
    </Router>
  );
}

export default App;
